
import { svgLinkedIn, svgFacebook, svgInstagram, svgEnvelope } from "../assets/logos";

const menu = [
  "About", "Education", "Certificates",
  //  "Projects"
];
const skills = ["JavaScript TypeScript",
  "ReactJS Node.js",
  "AmazonWebServices",
]
const certificates = [{
  "title": "AWS Certified Cloud Practitioner",
  "date": "November 2021",
  "image": "images/aws.png",
  "link": "https://www.credly.com/badges/7c4af087-f8a9-449d-b988-1a66ea9da90b/linked-in"
}];
const education = [
  {
    "title": "Master's Degree in Computer Science",
    "description": "Belarusian State University, Minsk, Belarus",
  }
];
const courses=[
  {
    "title": "JavaScript Basics",
    "description": "Codecademy",
  },
  {
    "title": "Source Control with Git",
    "description": "Codecademy",
  },
  {
    "title": "Node.js Backends and APIs",
    "web": "Code with Mosh",
    "url": "https://codewithmosh.com/p/the-complete-node-js-course"
  },
  {
    "title": "MongoDB with node.js",
    "web": "Code with Mosh",
    "url": "MongoDB"
  },
  {
    "title": "Frontend with ReactJS: components, routing, forms",
    "web": "Code with Mosh",
    "url": "https://codewithmosh.com/p/ultimate-react-part2"
  }
]
const socials = [{
  "icon": svgLinkedIn,
  "link": "https://www.linkedin.com/in/natalia-babicheva-36a924b7/",
}, {
  "icon": svgFacebook,
  "link": "https://www.facebook.com/nnbabicheva",
},
{
  "icon": svgInstagram,
  "link": "https://www.instagram.com/nattababi/"
},
{
  "icon": svgEnvelope,
  "link": "mailto:nataly.babicheva@gmail.com"
}];

export { skills, socials, certificates, education, courses, menu };