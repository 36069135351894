import React, { useState } from 'react';
import { menu, skills, socials, certificates, education, courses } from '../data/skills';
import '../styles.css';

const Dashboard = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    return <div className='dashboard-container'>
        <div>
            <div className='my-3'>Natalia Babicheva</div>
            <div className='my-3-tagline'>
                Full Stack Developer
            </div>
            <div className="social-icons">
                {socials.map((item, index) => <a className="footer-icon-lg" key={`social-item-${index}`} href={item.link} target="_blank" rel="noreferrer">{item.icon}</a>)}
            </div>
        </div>
        <nav><ul>{menu.map((item, index) => <li key={`menu-item-${index}`}><div className={`main-menu ${activeIndex === index ? 'active' : ''}`} onClick={() => { setActiveIndex(index); }}>{item}</div></li>)}</ul></nav>
        <div className='my-2'>
            {menuComponents[activeIndex]()}
        </div>

    </div>;
};

const MySkills = () => <>
    <div className="resume-header">Skills</div>
    <div className='skill-items'>
        {skills.map((item, index) => <div className='skill-item' key={`skill-item-${index}`}>{item}</div>)}
    </div>
    <div className="resume-icons">
        {socials.map((item, index) => <a className="footer-icon-lg" key={`social-item-${index}`} href={item.link} target="_blank" rel="noreferrer">{item.icon}</a>)}
    </div>
</>

const MyEducation = () => <>
    <div className="resume-header">Education</div>
    {education.map((item, index) => <div className="resume-item-edu" key={`education-item-${index}`}>
        <div><b>{item.title}</b></div>
        <div>{item.description}</div>
    </div>)}
    <div className="resume-header">Courses</div>
    {courses.map((item, index) =>
        <div className="resume-item" key={`course-item-${index}`}>{item.title}{item.description ? `, ${item.description}` : ''}
            {item.url && <a className='resume-item-link' href={item.url} target="_blank" rel="noreferrer">{item.web ? item.web : 'Link'}</a>}
        </div>)}
</>
const MyCertificates = () => <div>
    {certificates.map((item, index) => <div className="resume-item-1" key={`certificate-item-${index}`}>
        <img src={item.image} alt={item.title} className="certificate-image"></img>
        <div className='certificate-ref'>
            {item.title}
            <div>{item.date}</div>
        </div>
    </div>)}
</div>

const MyProjects = () => <>Coming soon</>

const menuComponents = [
    MySkills,
    MyEducation,
    MyCertificates,
    MyProjects
]

export default Dashboard;